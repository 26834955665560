// Colors
$darkBlue: hsl(233, 26%, 24%);
$limeGreen: hsl(136, 65%, 51%);
$brightCyan: hsl(192, 70%, 51%);
$grayishBlue: hsl(233, 8%, 62%);
$lightGrayishBlue: hsl(220, 16%, 96%);
$veryLightGray: hsl(0, 0%, 98%);
$white: hsl(0, 0%, 100%);

// Font Sizes
$font-sm: 0.875rem; // 14px
$font-med: 1.125rem; // 18px
$font-xlg: 3.25rem; //52px
