.footer {
    background-color: $darkBlue;
    color: $white;
    padding: 2.5rem;
    text-align: center;


    .container {
        
        @include breakpoint-up(large){
            display: grid;
            grid-template-columns: 2fr 1fr 1fr 3fr;
            grid-template-rows: 1fr auto;
            grid-template-areas: 
            "logo links1 links2 cta"
            "social links1 links2 copyright";
            gap: 1rem;
            justify-items: start;
        }
    }

    a {
        color: $white;
    }

    &__logo {
        display: inline-block;

        @include breakpoint-down(medium){
            margin-bottom: 1.875rem;
        }

        @include breakpoint-up(large){
            grid-area: logo;
        }

    }

    &__social {

        @include breakpoint-down(medium){
            margin-bottom: 1.875rem;
        }

        @include breakpoint-up(large){
            grid-area: social;
            align-self: end;
        }

        a {
            display: inline-block;
            height: 1.25rem;

            svg path {
                transition: fill 150ms ease-in-out;
            }

            &:hover svg path {
                fill: $limeGreen;
            }
        }

        a:not(:last-child) {
            margin-right: 1rem;
        }

    }

    &__links{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 0.9375rem;

        @include breakpoint-up(large){
            justify-content: space-between;
            align-items: start;
        }

        &.col1 {

            @include breakpoint-up(large){
                grid-area: links1;
            }

        }

        &.col2 {
            
            @include breakpoint-down(medium){
                margin-bottom: 1.875rem; 
            }
            
            @include breakpoint-up(large){
                grid-area: links2;
            }
        }

        a {
            line-height: 2.25rem;
            transition: color 150ms ease-in-out;

            &:hover {
                color: $limeGreen;
            }
        }
    }

    &__cta {

        @include breakpoint-up(large){
            grid-area: cta;
            text-align: right;
            justify-self: end;
        }

        a.button {

            @include breakpoint-down(medium){
                margin-bottom: 1.875rem;
            }

        }
    }

    &__copyright {
        font-size: 0.8125rem;
        color: $grayishBlue;

        @include breakpoint-up(large){
            grid-area: copyright;
            align-self: end;
            justify-self: end;
        }

    }
}