.header {

    // Hamburger Open Styles

    &.open {        

        .header__toggle {

            >span:first-child {
                transform: rotate(45deg);
            }
    
            >span:nth-child(2) {
                opacity: 0;
            }
    
            >span:last-child {
                transform: rotate(-45deg);
            }
        }        
    }

    .overlay {
        opacity: 0;
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-image: linear-gradient($darkBlue, transparent);        
    }

    nav {
        position: relative;
        background-color: $white;
        padding-top: 1.0625rem;
        padding-bottom: 1.0625rem;
    }

    &__logo{

        img {
            width: 8.8125rem;
            height: 1.375rem;
        }

    }

    &__toggle{ // Mobile Menu toggle

        > span {
            display: block;
            width: 26px;
            height: 2px;
            background-color: $darkBlue;
            transition: all 300ms ease-in-out;
            transform-origin: 0.1875rem 0.0625rem;

            &:not(:last-child){
                margin-bottom: 0.3125rem;
            }
        }       

    }

    &__menu {
        position: absolute;
        width: calc(100% - 3rem);
        left: 50%;
        transform: translateX(-50%);
        background: $white;
        margin-top: 1.5rem;
        padding: 1.625rem;
        border-radius: 5px;

        a {
            display: block;
            padding: 0.625rem;
            color: $darkBlue;            
            text-align: center;
        }
    }

    &__links {
        a {
            position: relative;
            font-size: $font-sm;
            color: $grayishBlue;
            transition: color 300ms ease-in-out;

            &:not(:last-child){
                margin-right: 2rem;
            }

            &::before {
                content: "";
                display: block;
                position: absolute;
                border-radius: 0.3125rem;
                height: 0.3125rem;
                left: 0;
                right: 0;
                bottom: -1.875rem;
                background: linear-gradient(to right,  $limeGreen, $brightCyan);
                opacity: 0;
                transition: opacity 300ms ease-in-out;
            }

            &:hover {
                color: $darkBlue;

                &::before {
                    opacity: 1;
                }
            }
        }
    }    
}



